import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader'
import ContactArea from '../containers/global/contact-area/contact-four'

const CovidPolicyPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO title="Covid Policy" />
    <Header/>
    <PageHeader 
        pageContext={pageContext} 
        location={location}
        title="Covid Policy"
    />
    <main className="site-wrapper-reveal">
        <div class="container accordian-container">
          <br/> <br/>
          <h3 class="text-center">Covid 19 Policy</h3>
          <ul>
            <li>We will arrive at your home in a clean mask.</li>
            <li>Lateral flow testing carried out before all client sessions.</li>
            <li>Regular hand sanitising will take place throughout the session, and we will bring our own hand sanitiser, surface cleaning spray, and organising tools needed for each session.</li>
            <li>We will practice 2-metre social distancing (where possible).</li>
            <li>Windows to be open and rooms to be well ventilated where possible.</li>
            <li>We will check our temperature regularly and will not attend the appointment if feeling unwell. We ask all our clients to let us know in advance if they are feeling unwell at all.</li>
            <li>The number of client sessions we are offering has been reduced to avoid visiting clients too closely together.</li>
            <li>We are not able to work with those who are vulnerable or listed as high risk, or that live with anyone that is vulnerable or high risk.</li>
            <li>We are currently only able to work with clients who can provide private parking at their home address, so travel to your home is by car only.</li>
            <li>Any questions about this, or anything else, please get in touch 😊</li>
          </ul>
        </div>
        <ContactArea/>
    </main>
    <Footer/>
  </Layout>
)
 
export default CovidPolicyPage
 